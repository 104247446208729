import { Card, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface DesciptionInput {
    title : string;
    desc : string;
}

const GameDescriptionCard = ({title, desc} : DesciptionInput) => {

    return (
        <Card style={{margin :'4%'}}>
            <Card.Body>
                <Row style={{marginBottom : "1%"}}>
                    <Col>
                        <Card.Title>
                        {title}
                        </Card.Title>
                    </Col>
                </Row>
                <Card.Text>
                    {desc}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default GameDescriptionCard;