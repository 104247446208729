import { Alert, Row, Col } from 'react-bootstrap';
import CopyBox from './CopyBox';

interface AlertCopyInfo {
    alert_level : number,
    alert_msg : string,
    game_id : string
}

const AlertWrapperWithCopy = ({alert_level, alert_msg, game_id} : AlertCopyInfo) => {
    if (alert_level == 0) {
        return (<></>);
    }
    else if (alert_level == 1){
        return (<Alert variant='danger' style={{fontFamily : 'monospace', fontSize : 'smaller'}}>
            {alert_msg}
        </Alert>);
    } else {
        return (<Alert variant='success' style={{fontFamily : 'monospace', fontSize : 'smaller'}}>
            <Row><Col>{alert_msg}<CopyBox copyText={game_id}></CopyBox></Col> </Row>
        </Alert>);
    }
}

export default AlertWrapperWithCopy;