import CodingPad from "../components/CodingPad";
import { Container, Row, Col, Card, Stack, Button } from "react-bootstrap";
import GameDescriptionCard from "../components/GameDescriptionCard";
import { useState, useRef } from "react";
import CodeSubmitButton from "../components/CodeSubmitButton";
import { useNavigate } from "react-router-dom";

const TicTacToe = () => {

    const default_code : string = 
`# Boilerplate for Tic Tac Toe

## These functions have been imported for you in the environment
## from Tic_Tac_Toe.utils import generate_init_state
## from Tic_Tac_Toe.utils import generate_rand_move
## from Tic_Tac_Toe.utils import print_state
## from Tic_Tac_Toe.utils import is_valid_move
## from Tic_Tac_Toe.utils import invert_board
## from Tic_Tac_Toe.utils import state_change
## from Tic_Tac_Toe.utils import is_game_over
## from Tic_Tac_Toe.utils import winner
## from Tic_Tac_Toe.utils import is_board_full

class PlayerAI:
    def make_move(self, board):
        # Implement your AI logic here to determine the best move
        # Example AI logic: choose a random empty cell
        move = generate_rand_move(board)
        if move:
            return move

        # If no empty cell is found, return None
        return None
    `;

    const editorRef = useRef();

    const desc = "The goal of this game is to get 3 of your symbols aligned in either a horizontal, vertical or diagonal direction.";


    const [value, setValue] = useState(default_code)

    const onChangeFunc = (v : any) =>  {
        setValue(v);
        console.log(v);
    }

    const onMount = (editor : any) => {
        editorRef.current = editor;
        editor.focus();
        console.log('mounted')
    }
    console.log("ref:", editorRef.current)

    const navigate = useNavigate();

    const onClick = () => {
       navigate("/tttreplay");
    }

    return ( 
        <div className="main-content">
            <Row l={2} >
                <Col>
                    <CodingPad on_change_func={onChangeFunc} on_mount={onMount} code={default_code}></CodingPad>
                </Col>  
                <Col style={{backgroundColor:'white'}}>
                    <Stack gap={1}>
                        <Col style={{paddingTop :  "1%", justifyContent : "flex-end", alignItems : "right", display : "flex"}}>
                            <Button size="sm" variant="dark" style={{marginRight : "4%"}} onClick={onClick}>Replays</Button>
                        </Col>
                        <GameDescriptionCard title='Tic Tac Toe' desc={desc}></GameDescriptionCard>
                        
                        <CodeSubmitButton editor_ref={editorRef} game={"tic-tac-toe"}></CodeSubmitButton>
                    </Stack>
                </Col>
            </Row>
        </div>
    );
}

export default TicTacToe;