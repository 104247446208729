import React, { useState, useEffect } from 'react';
import { Stack, Row, Col, Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HistoryList, { HistoryInput } from '../components/HistoryList';
import useReplay from '../hooks/useReplay'; 

const TTTReplayPage: React.FC = () => {

    const [display, setDisplay] = useState(<Container>
        <div>
            Select a game to replay!
        </div>
    </Container>);

    const navigate = useNavigate();
    const { replays, loading, error, fetchReplays } = useReplay('tic-tac-toe');
    useEffect(() => {
        fetchReplays(); // Fetch replays when component mounts or game type changes
    }, []);



    // Hardcoded moves for testing
    const history : HistoryInput[] = [
        {
            replay_id : "1",
            timestamp : "testing time!",
            score : 0,
            replay:[]
        },

        {
            replay_id : "2",
            timestamp : "testing time!",
            score : 0,
            replay: []
        }
    ]

    const onClick = () => {
        navigate("/tictactoe");
    }
    
    return (
        <div className='main-content'>
            <Stack>
                <Row>
                    <Col>
                        <div style={{marginLeft : '2%', fontSize: '50px'}}>Replays</div>
                    </Col>
                    <Col md="auto" style={{padding:"1%", marginRight:"2%"}}>
                        <Button variant="dark" onClick={onClick}>Back</Button>
                    </Col>
                </Row>
                <Row gap={1}>
                    <Col style={{padding:'3%'}}>
                        <HistoryList 
                        gametype='ttt'
                        set_display={setDisplay}
                        historylist={replays} />
                    </Col>
                    <Col style={{marginLeft : '10%'}}>
                        {display}
                    </Col>
                </Row>
            </Stack>
        </div>
    );
};

export default TTTReplayPage;