import { useState } from 'react';
import { useAuthContext } from './useAuthContext';
import { useNavigate } from 'react-router-dom';
import { setJwtToken } from '../scripts/JWTToken';
import GLOBAL_PATH from './GlobalAPI';

const SIGNUPPATH = `${GLOBAL_PATH}signup`;

export const useSignup = () => {

    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const { dispatch } = useAuthContext();

    const signup = async (email : string, password : string) => {
        setLoading(true);
        setError(null);
        const params = new URLSearchParams({ email, password });

        const resp = await fetch(`${SIGNUPPATH}?${params.toString()}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        });

        const json = await resp.json();

        if (!resp.ok) {
            console.log("Error in signup!")
            setLoading(false);
            setError(json.message);
            console.warn(json.message);
        } else {
            setJwtToken(JSON.stringify(json));
            dispatch({type : "LOGIN", payload : json});
            setLoading(false);
            navigate('/');
        }
    };
    return {signup, isLoading, error};
};