import { Container } from "react-bootstrap";
import CodeIdCard from "../components/CodeIdCard";

const CodeIdPage = () => {

    return (
        <Container
            style={{
                margin: '5%',
            }}
        >
           <CodeIdCard></CodeIdCard>
        </Container>
    );
}


export default CodeIdPage;