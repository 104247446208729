import { Container } from "react-bootstrap";
import MatchCard from "../components/MatchCard";

const MatchingPage = () => {

    return (
        <Container
            style={{
                margin: '5%',
            }}
        >
           <MatchCard></MatchCard>
        </Container>
    );
}


export default MatchingPage;