import { useState } from "react";
import { getJwtToken } from "../scripts/JWTToken";
import GLOBAL_PATH from "./GlobalAPI";

const SUBMITPATH = `${GLOBAL_PATH}submit_code`;

export const useSubmitCode = () => {

    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState(0);

    const submit = async (editorval : string, game: string) => {
        
        setAlert(0);


        const formdata = new FormData();
        const file = new Blob([editorval], {type : 'plain/text'});
        formdata.append('game', game);
        formdata.append('user_code', file, 'solution.py');
    
        const url = `${SUBMITPATH}`;
        
        const resp = await fetch(url, {
            method: 'POST',
            credentials : 'include',
            body: formdata
        });

        console.log(resp)
        const json = await resp.json();
        console.log(json)
        
        if (!resp.ok) {
            console.log("Error in submission!");
            setMessage("Please try again!");
            setAlert(1);
        } else {
            console.log("submit ok!")
            setMessage("Successfully Submitted!");
            setAlert(2);
        };
    };

    return {submit, message, alert};
}