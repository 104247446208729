import { Alert } from 'react-bootstrap';

interface AlertInfo {
    alert_level : number,
    alert_msg : string
}

const AlertWrapper = ({alert_level, alert_msg} : AlertInfo) => {
    if (alert_level == 0) {
        return (<></>);
    }
    else if (alert_level == 1){
        return (<Alert variant='danger' style={{fontFamily : 'monospace', fontSize : 'smaller'}}>
            {alert_msg}
        </Alert>);
    } else {
        return (<Alert variant='success' style={{fontFamily : 'monospace', fontSize : 'smaller'}}>
            {alert_msg}
        </Alert>);
    }
}

export default AlertWrapper;