import React, { useEffect, useState } from 'react';
import { Container, Button, Row } from 'react-bootstrap';

interface Move {
    position: [number, number];
}

interface TTTReplayBoardProps {
    selected_id : string;
    moves : {position: [number, number]}[];
}

const TTTReplayBoard: React.FC<TTTReplayBoardProps> = ({ selected_id, moves }) => {

    const defaultboard : string[][] = [  ['', '', ''],
                            ['', '', ''],
                            ['', '', '']]

    const [board, setBoard] = useState<string[][]>(defaultboard);


    const renderMove = (move: Move, moveIndex: number) => {
        setBoard(prevBoard => {
            const newBoard = [...prevBoard];
            const [row, col] = move.position;
            newBoard[row][col] = moveIndex % 2 === 0 ? 'X' : 'O';
            return newBoard;
        });
    };

    const play = () => {
        
        const playReplay = () => {
            let moveIndex = 0;
            setBoard(defaultboard);
            
            const nextMove = () => {
                if (moveIndex < moves.length) {
                    renderMove(moves[moveIndex], moveIndex);
                    moveIndex++;
                    setTimeout(nextMove, 1000); // Adjust the timing as needed
                }
            };
            nextMove();
        };

        playReplay();
    }

    return (
        <Container id={selected_id} style={{padding:'2%'}}>
            <Row>
                Replay for {selected_id}
            </Row>
            <div id={selected_id} className="board">
            {board.map((row, rowIndex) => (
                <div id={selected_id} key={rowIndex} className="row">
                    {row.map((cell, colIndex) => (
                        <div id={selected_id} key={colIndex} className="cell">
                            {cell}
                        </div>
                    ))}
                </div>
            ))}
        </div>
        <Button variant="dark" size="sm" onClick={play}>Start Replay</Button>
        </Container>
    );
};

export default TTTReplayBoard;
