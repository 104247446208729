import { useState } from "react";
import GLOBAL_PATH from "./GlobalAPI";

const CODE_ID_PATH = `${GLOBAL_PATH}get_code_id`;

export const useGetCodeId = () => {

    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState(0);
    const [code_id, setCodeId] = useState("");

    const getCodeId = async (game : string) => {
        const url = `${CODE_ID_PATH}?game=${game}`;

        const resp = await fetch(url, {
            method: 'GET',
            credentials : 'include'
        });
        
        console.log(resp);
        const json = await resp.json();

        if (!resp.ok) {
            console.log("Error in submission!");
            setMessage("Please try again!");
            setAlert(1);
        } else {
            console.log("submit ok!")
            setCodeId(`${json.code_id}`);
            setMessage("Success! Your agent ID is: ")
            setAlert(2);
        };
    }

    return {message, alert, code_id, getCodeId};
}