import Form from 'react-bootstrap/Form';
import { Button, Stack } from 'react-bootstrap';
import { useState } from 'react';
import { useLogin } from '../hooks/useLogin';


const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [warning, setWarning] = useState("");
    const { login, isLoading, error } = useLogin();

    const updatePassword = (event : any) => {
      setPassword(event.target.value);
    }

    const updateEmail = (event : any) => {
      setEmail(event.target.value);
    }

    const handleSubmit = async (event : any) => {
      event.preventDefault();
      setWarning("");
      // handleLogin({email, password}); TODO
      await login(email, password);
      setWarning("Something went wrong, please check your inputs.");
    }

    return (
        <Form style={{margin : '4%', fontFamily : 'monospace'}} onSubmit={handleSubmit}>
          {/* <Form.Text style={{fontSize : '400%', fontWeight : 'bolder', marginBottom : '4%'}}>Login</Form.Text> */}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control value={email} onChange={updateEmail} type="email" placeholder="Enter email" />
          </Form.Group>
    
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control value={password} onChange={updatePassword} type="password" placeholder="Password" />
          </Form.Group>
          <Stack>
            <Button variant="outline-secondary" type="submit">
              Login
            </Button>
            <Form.Text>{warning}</Form.Text>
          </Stack>
        </Form>
      );
}

export default Login;