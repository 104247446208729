import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Nav } from 'react-bootstrap';

import '../App.css';

const NavBar = () => {

  return (
    <Navbar expand="lg" className='nav-main'>
            <Container  className="nav-container">
                <Navbar.Brand className='navlink' href="/" style={{color:'white'}}>AlgoBrawl</Navbar.Brand>
                {/* <Nav className="me-auto">
                    <Nav.Link className="navlink" href="/">Home</Nav.Link>
                    <Nav.Link className="navlink" href="/">Logout</Nav.Link>
                </Nav> */}
                <Nav>
                  <Nav.Link className="navlink" href="/leaderboard" style={{color:'white'}}>Leaderboards</Nav.Link>
                  <Nav.Link className="navlink" href="/matchup" style={{color:'white'}}>PVP</Nav.Link>
                  <Nav.Link className="navlink" href="/get_code_id" style={{color:'white'}}>Agent ID</Nav.Link>
                </Nav>
            </Container>
    </Navbar>
  );
}

export default NavBar;