import { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import TTTReplayCard from "./TTTReplayCard";
import BTReplayCard from "./BTReplay";

export interface HistoryInput {
    replay_id : string;
    timestamp : string;
    score : number;
    replay: any;
}

interface HistoryInputList {
    set_display  : React.Dispatch<any>;
    gametype : string;
    historylist : HistoryInput[];
}

const HistoryList = ({set_display, gametype, historylist } : HistoryInputList) => {
    
    const default_output : JSX.Element[] = []; 
    const [loading, setLoading] = useState(false);
    const [output, setOutput] = useState(default_output);

    useEffect(() => {
        setLoading(true);
        setOutput([]);
        
        let p_mapped : JSX.Element[] = historylist.map((v : HistoryInput) => {
            
            var component : JSX.Element = <Row></Row>;
            if (gametype == 'ttt') {
                component = (<Row>
                    <TTTReplayCard 
                        set_display={set_display}
                        replay_id={v.replay_id} 
                        timestamp={v.timestamp} 
                        score={v.score}
                        replay = {v.replay} />
                    </Row>);
            }
            else if (gametype == 'bt') {
                // console.log("im using bt");
                component = (<Row>
                    <BTReplayCard 
                        set_display={set_display}
                        replay_id={v.replay_id} 
                        timestamp={v.timestamp} 
                        score={v.score}
                        replay = {v.replay} />
                    </Row>);
            } 
            return (component);
        });

        setOutput(p_mapped);
        setLoading(false);
        }
    );

    return (<>
        <div className='vertical-scrollable'>
            {output}
        </div>
    </>);

}

export default HistoryList;