import { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import ScoreCard from './ScoreCard';

import '../App.css';

export interface RankInput {
    email : string,
    elo : number,
    rank : number
}

export interface ScoreInput {
    user_id : string,
    elo : number
}

export interface ScoreInputList {
    scorelist : ScoreInput[]
}

const Scorelist = ({ scorelist } : ScoreInputList) => {
    
    const default_output : JSX.Element[] = []; 
    const [loading, setLoading] = useState(false);
    const [output, setOutput] = useState(default_output);

    useEffect(() => {
        setLoading(true);
        setOutput([]);
        let ranks : number[] = [];
        for (var i : number = 1; i <= scorelist.length; i++) {
            ranks.push(i);
        }
        let p_mapped : JSX.Element[] = ranks.map((i : number) => {
            var v : ScoreInput = scorelist[i-1];
            return (
                <Row>
                    <ScoreCard email={v.user_id} elo={v.elo} rank={i}></ScoreCard>
                </Row>
                );
            });
        setOutput(p_mapped);
        setLoading(false);
        }
    );

    return (<>
        <div className='vertical-scrollable'>
            {output}
        </div>
    </>);
}

export default Scorelist;