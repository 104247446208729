import { useState } from "react";
import { Container, Stack } from "react-bootstrap";


import Login from '../components/Login';
import Signup from '../components/Signup';
import { useAuthContext } from "../hooks/useAuthContext";

const AuthenticationPage = () => {
    const [loggingIn, setLoggingIn] = useState(true);
    
    const changeLogin = () => {
        setLoggingIn(!loggingIn);
    }

    return (
        <Container>
            {loggingIn ? (
                <Stack>
                    <Login></Login>
                    <button onClick={changeLogin} 
                    style={{fontFamily:'monospace',
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        margin: 0,
                        display : 'flex',
                        marginLeft : '4%',
                        marginBottom : '4%',
                        fontSize : '80%'
                        }}>
                        Don't have a account? Signup instead!</button>
                </Stack>
            ) : (
                <Stack>
                    <Signup></Signup>
                    <button onClick={changeLogin} 
                    style={{fontFamily:'monospace',
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        margin: 0,
                        display : 'flex',
                        marginLeft : '4%',
                        marginBottom : '4%',
                        fontSize : '80%'
                        }}>Already have a account? Login instead!</button>
                </Stack>
            )}
        </Container>
    );
}

export default AuthenticationPage;