import { useState } from 'react';
import GLOBAL_PATH from "./GlobalAPI";

const LEADERBOARDPATH = `${GLOBAL_PATH}leaderboard`;

const useLeaderboard = (game: string) => {
    const [leaderboard, setLeaderboard] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchLeaderboard = async () => {
        setLoading(true);
        setError(null);

        try {
            const url = `${LEADERBOARDPATH}?game=${game}`; // Send game as query parameter
            const resp = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!resp.ok) {
                throw new Error('Failed to fetch leaderboard');
            }

            const json = await resp.json();
            setLeaderboard(json.leaderboard);
        } catch (error) {
            setError('Error fetching leaderboard');
            console.error('Error fetching leaderboard:', error);
        } finally {
            setLoading(false);
        }
    };

    return { leaderboard, loading, error, fetchLeaderboard };
};

export default useLeaderboard;
