import { useState } from "react";
import GLOBAL_PATH from "./GlobalAPI";

const MATCHPATH = `${GLOBAL_PATH}run_game`;

export const useMatch = () => {

    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState(0);



    const match = async (opponent : string, game : string, is_ranked: boolean) => {
        const url = `${MATCHPATH}?player_two_code_id=${opponent}&game=${game}&is_ranked=${is_ranked}`;

        const resp = await fetch(url, {
            method: 'POST',
            credentials : 'include'
        });

        console.log(resp);

        if (!resp.ok) {
            console.log("Error in submission!");
            setMessage("Please try again!");
            setAlert(1);
        } else {
            console.log("submit ok!")
            setMessage("Match Request Submitted!");
            setAlert(2);
        };
    }

    return {match, message, alert};
}
