import Card from 'react-bootstrap/Card';
import { useState } from 'react';
import { Button, Form, Dropdown, DropdownButton, Stack } from 'react-bootstrap';
import { useMatch } from '../hooks/useMatch';
import AlertWrapper from './AlertWrapper';
import { useAuthContext } from '../hooks/useAuthContext';

function MatchCard() {

    const { match, message, alert } = useMatch();

    const [game, changeGame] = useState("Tic-Tac-Toe");

    const [oppid, changeID] = useState("");

    const [isRanked, setIsRanked] = useState(false);


    const handleIDChange = (event : any) => {
        changeID(event.target.value);
    }
    
    const handleRankedChange = (event: any) => {
        setIsRanked(event.target.checked);
    };


    const handleSubmit = async () => {
        // const ctx = useAuthContext();

        var fgame : string = "";
        if (game == "Tic-Tac-Toe") {
            fgame = "tic-tac-toe"; 
        } else if (game == "BreakThrough") {
            fgame = "breakthrough";
        } else {
            console.log("invalid game recieved");
        }

        await match(oppid, fgame, isRanked);
    }

    return (
        <Card>
          <Card.Body>
            <Card.Title>PVP</Card.Title>
            <Card.Text>
                Play against another player by adding his agent-id.
            </Card.Text>
            <Form>
                <Form.Group>
                    <Form.Label>Opponent ID</Form.Label>
                    <Form.Control type="text" value={oppid} onChange={handleIDChange} placeholder="Enter Opponent ID" />
                </Form.Group>
                <Form.Group>    
                    <Form.Label>Game</Form.Label>
                    <DropdownButton
                        variant="outline-secondary"
                        title={game}
                        id="input-group-dropdown-1"
                        >
                        <Dropdown.Item onClick={() => {changeGame("BreakThrough");}}>Breakthrough</Dropdown.Item>
                        <Dropdown.Item onClick={() => {changeGame("Tic-Tac-Toe");}}>Tic Tac Toe</Dropdown.Item>
                    </DropdownButton>
                </Form.Group>
                <Form.Group controlId="formIsRanked">
                <Form.Check
                type="checkbox"
                label="Ranked Match"
                checked={isRanked}
                onChange={handleRankedChange}
                />
            </Form.Group>
            </Form>
            <Stack style={{marginTop : '3%'}}>
                <Button variant="outline-secondary" type="submit" onClick={handleSubmit}>
                    Let's Fight!
                </Button>
                <Stack style={{marginTop : '3%'}}>
                    <AlertWrapper alert_level={alert} alert_msg={message}></AlertWrapper>
                </Stack>
            </Stack>
          </Card.Body>
        </Card>
      );
  }
  
  export default MatchCard;