"use client";

import Editor from '@monaco-editor/react';

interface CodingPadInput {
    code : string;
    on_change_func : React.Dispatch<React.SetStateAction<any>>;
    on_mount : any;
}

const CodingPad = ({code, on_change_func, on_mount} : CodingPadInput) => {
    return (
        <Editor 
        height="90vh" 
        defaultLanguage="python" 
        defaultValue={code} 
        theme="vs-dark" 
        onChange={(v) => on_change_func(v)}
        onMount={on_mount}
        />
    )
}

export default CodingPad;