import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

interface GameCardProps {
    game_title: string;
    image_link: string;
    game_page: string;
}

function GameCard({game_title, image_link, game_page} : GameCardProps) {
    return (
        <Card style={{ width: '18rem', backgroundColor : 'grey', color: 'white', fontFamily: 'monospace'}}>
          <Card.Img variant="top" style={{width:'286px', height: '180px'}} src={image_link}/>
          <Card.Body>
            <Card.Title>{game_title}</Card.Title>
            <Button href={game_page} variant="outline-light">Let's Play</Button>
          </Card.Body>
        </Card>
      );
  }
  
  export default GameCard;