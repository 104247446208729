import { Tabs, Tab, Container, Stack, Spinner, Alert } from "react-bootstrap"
import { ScoreInput, ScoreInputList } from "../components/Scorelist"
import Scorelist from "../components/Scorelist"
import useLeaderboard from "../hooks/useLeaderboard";
import { useState, useEffect } from "react";


const Leaderboard = () => {
    // State to track which tab is active
    const [activeTab, setActiveTab] = useState<string>('tic-tac-toe');
    const [ttScoreCache, setTtScoreCache] = useState<ScoreInput[] | null>(null);
    const [btScoreCache, setBtScoreCache] = useState<ScoreInput[] | null>(null);

    const { leaderboard: tt_score, loading: tt_loading, error: tt_error, fetchLeaderboard: fetchTTTLeaderboard } = useLeaderboard('tic-tac-toe');
    const { leaderboard: bt_score, loading: bt_loading, error: bt_error, fetchLeaderboard: fetchBTLeaderboard } = useLeaderboard('breakthrough');

    // Fetch leaderboard only when the tab is active and if data is not cached
    useEffect(() => {    
        if (activeTab === 'tic-tac-toe' && ttScoreCache?.length == 0) {
            fetchTTTLeaderboard();
        } else if (activeTab === 'breakthrough' && btScoreCache?.length == 0) {
            console.log("Updating")
            fetchBTLeaderboard();
        }
    }, [activeTab, ttScoreCache, btScoreCache, fetchTTTLeaderboard, fetchBTLeaderboard]);

    // Cache the leaderboard data once it's fetched
    useEffect(() => {
        if (tt_score && !tt_loading && !tt_error) {
            setTtScoreCache(tt_score as ScoreInput[]);
        }
    }, [tt_score, tt_loading, tt_error]);

    useEffect(() => {
        if (bt_score && !bt_loading && !bt_error) {
            setBtScoreCache(bt_score as ScoreInput[]);
        }
    }, [bt_score, bt_loading, bt_error]);

    return (
        <Stack>
            <Container>
                <div style={{ fontFamily: 'monospace', fontSize: '50px', padding: '2%' }}>
                    Leaderboards
                </div>
            </Container>
            <Container>
                <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k!)}
                    className="mb-3"
                    style={{ fontFamily: 'monospace', padding: '2%' }}
                >
                    <Tab eventKey="tic-tac-toe" title="Tic Tac Toe">
                        {/* {tt_loading && <Spinner animation="border" />}
                        {tt_error && <Alert variant="danger">{tt_error}</Alert>} */}
                        {!tt_loading && !tt_error && ttScoreCache && (
                            <Scorelist scorelist={ttScoreCache} />
                        )}
                    </Tab>
                    <Tab eventKey="breakthrough" title="Breakthrough">
                        {/* {bt_loading && <Spinner animation="border" />}
                        {bt_error && <Alert variant="danger">{bt_error}</Alert>} */}
                        {!bt_loading && !bt_error && btScoreCache && (
                            <Scorelist scorelist={btScoreCache} />
                        )}
                    </Tab>
                </Tabs>
            </Container>
        </Stack>
    );
};

export default Leaderboard;