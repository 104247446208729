import Button from 'react-bootstrap/Button';
import { useSubmitCode } from '../hooks/useSubmitCode';
import AlertWrapper from './AlertWrapper';


interface EditorOutput {
  editor_ref : React.MutableRefObject<any>
}
interface CodeSubmitButtonProps extends EditorOutput {
  game: string;
}

const CodeSubmitButton: React.FC<CodeSubmitButtonProps> = ({ editor_ref, game }) => {

  const { submit, message, alert} = useSubmitCode();

  const submitCode = async () => {
    var current : any = editor_ref.current.getValue();
    await submit(current, game);
  }

  return (
    <div className="d-grid gap-2" style={{margin:'4%'}}>
      <Button variant="dark" size="lg" onClick={submitCode}>
       Submit Code
      </Button>
      <AlertWrapper alert_level={alert} alert_msg={message}></AlertWrapper>
    </div>
  );
}

export default CodeSubmitButton;