import CodingPad from "../components/CodingPad";
import { Container, Row, Col, Card, Stack, Button } from "react-bootstrap";
import GameDescriptionCard from "../components/GameDescriptionCard";
import CodeSubmitButton from "../components/CodeSubmitButton";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

const BreakThrough = () => {

    const editorRef = useRef();

    const default_code : string = 
`# Boilerplate for BreakThrough

## These functions have been imported for you in the environment
## from Breakthrough.utils import generate_init_state
## from Breakthrough.utils import generate_rand_move
## from Breakthrough.utils import  ROW, COL, INF, WIN, MOVE_NONE, TIME_LIMIT
## from Breakthrough.utils import print_state
## from Breakthrough.utils import is_valid_move
## from Breakthrough.utils import invert_board
## from Breakthrough.utils import state_change
## from Breakthrough.utils import is_game_over

class PlayerAI:
    """A naive agent that will always return the first available valid move"""

    def make_move(self, board):
        return breakthrough_utils.generate_rand_move(board)
    `;

    const [value, setValue] = useState(default_code);

    const navigate = useNavigate();

    const onChangeFunc = (v : any) =>  {
        setValue(v);
    }

    const onMount = (editor : any) => {
        editorRef.current = editor;
        editor.focus();
        console.log('mounted')
    }

    const desc = "The goal of this game is to move your pawn to the otherside!";

    const onClick = () => {
        navigate("/btreplay")
    }

    return (
        <div className="main-content">
            <Row l={2} >
                <Col>
                    <CodingPad on_change_func={onChangeFunc} on_mount={onMount} code={default_code}></CodingPad>
                </Col>  
                <Col style={{backgroundColor:'white'}}>
                    <Stack gap={1}>
                        <Col style={{paddingTop :  "1%", justifyContent : "flex-end", alignItems : "right", display : "flex"}}>
                            <Button size="sm" variant="dark" style={{marginRight : "4%"}} onClick={onClick}>Replays</Button>
                        </Col>
                        <GameDescriptionCard title='BreakThrough' desc={desc}></GameDescriptionCard>
                        <CodeSubmitButton editor_ref={editorRef} game={"breakthrough"}></CodeSubmitButton>
                    </Stack>
                </Col>
            </Row>
        </div>
    );
}

export default BreakThrough;