import { useNavigate } from "react-router-dom"
import { getJwtToken } from "../scripts/JWTToken";
import { Navigate } from "react-router-dom";

const RequireAuthContext = ({ child } : any) => {
    const payload = getJwtToken();
    if (!payload) {
        return <Navigate to='/auth' replace />;
    } else {
        return child;
    }
}

export default RequireAuthContext;