import { useState } from 'react';
import GLOBAL_PATH from "./GlobalAPI";

const REPLAYPATH = `${GLOBAL_PATH}get_replay`;

const useReplay = (game: string) => {
    const [replays, setReplays] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchReplays = async () => {
        setLoading(true);
        setError(null);

        try {
            const url = `${REPLAYPATH}?game=${game}`; // Send game as query parameter
            const resp = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!resp.ok) {
                throw new Error('Failed to fetch replays');
            }

            const json = await resp.json();
            setReplays(json.replays);
        } catch (error) {
            // setError('Error fetching replays');
            console.error('Error fetching replays:', error);
        } finally {
            setLoading(false);
        }
    };

    return { replays, loading, error, fetchReplays };
};

export default useReplay;
