/*
    Uncomment above for testing localhost api path for local testing, otherwise 
    use the render api endpoint for deployment.
*/

// const GLOBAL_PATH : string = 'http://0.0.0.0:8000/';
// const GLOBAL_PATH : string = 'https://algoarena-backend-mwtg.onrender.com/';
const GLOBAL_PATH : string = 'https://api.algobrawl.com/';

export default GLOBAL_PATH;
