import Form from 'react-bootstrap/Form';
import { Button, Stack } from 'react-bootstrap';
import { useState } from 'react';
import { useSignup } from '../hooks/useSignup';
import { sign } from 'crypto';

const Signup = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [warning, setWarning] = useState("");
    const { signup, isLoading, error} = useSignup();

    const updatePassword = (event : any) => {
      setPassword(event.target.value);
    }

    const updateEmail = (event : any) => {
      setEmail(event.target.value);
    }

    const handleSubmit = async (event : any) => {
      event.preventDefault();
      setWarning("");
      await signup(email, password);
      setWarning("Something went wrong, please check your inputs.");
      // setWarning(error);
    }

    return (
        <Form style={{margin : '4%', fontFamily : 'monospace'}} onSubmit={handleSubmit}>
          {/* <Form.Text style={{fontSize : '1000px'}}>Signup</Form.Text> */}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" value={email} onChange={updateEmail} placeholder="Enter email" />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
    
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control value={password} onChange={updatePassword} type="password" placeholder="Password" />
          </Form.Group>
          <Stack>
            <Button variant="outline-secondary" type="submit">
              Signup
            </Button>
            <Form.Text>{warning}</Form.Text>
          </Stack>
        </Form>
      );
}

export default Signup;