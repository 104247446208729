import { Card, Row, Button, Col, Container } from "react-bootstrap";
import BTReplayBoard from "./BTReplayBoard";
import React, { forwardRef } from "react";
import { Move } from "./BTReplayBoard";

interface ReplayCardInput {
    set_display  : React.Dispatch<any>;
    replay_id : string;
    timestamp : string;
    score : number;
    replay: any;
}

function transformReplays(replays: number[][][]): Move[] {
    const moves: Move[] = replays.map((moveData) => ({
        startPosition: {
            position: moveData[0]
        },
        endPosition: {
            position: moveData[1]
        }
    }));
    return moves;
}


const BTReplayCard = ({set_display, replay_id, timestamp, score, replay} : ReplayCardInput) => {
    // var moves : Move[] = [
    //     {startPosition : { position: [1, 0] }, endPosition : { position : [2, 0]}},
    //     {startPosition : { position: [4, 0] }, endPosition : { position : [3, 0]}}
    // ];

    // var moves_test_2 : Move[] = [
    //     {startPosition : { position: [1, 2] }, endPosition : { position : [2, 2]}},
    //     {startPosition : { position: [4, 5] }, endPosition : { position : [3, 5]}}
    // ];


    // if (replay_id != '1') {
    //     moves = moves_test_2;
    // }
    var moves = transformReplays(replay);

    const updatePage = () => {
        console.log("updating ui to: " + replay_id);
        let selected_id = replay_id;
        let newReplay = React.createElement(BTReplayBoard, { selected_id, moves }, null);
        set_display(<Container>
            loading...
        </Container>)
        setTimeout(() => runReplay(newReplay), 1);
    }

    const runReplay = (newReplay : React.FunctionComponentElement<any>) => {
        set_display(<Container id={replay_id}>
            {newReplay}
        </Container>
        );
    }

    return (
        <Card style={{padding:'3%'}}>
            <Row>
                <Col>
                    <Card.Title>{score}</Card.Title>
                </Col>
                <Col md="auto" style={{padding:"1%", marginRight:"2%"}} >
                    <Button variant="dark" size="sm" onClick={updatePage}>
                    Replay
                    </Button>
                </Col>
            </Row>
            <Card.Subtitle>{replay_id}, {timestamp}</Card.Subtitle>
        </Card>
    );
}

export default BTReplayCard;