import React from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';

// Components
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

// Landing Page
import Landing from "./pages/Landing"

// Auth pages
import AuthenticationPage from './pages/AuthenticationPage';
import RequireAuthContext from './context/RequireAuthContext';

// Game pages
import TicTacToe from './pages/TicTacToe';
import TTTReplayPage from './pages/TicTacToeReplayPage';

import BreakThrough from './pages/BreakThrough';
import BreakThroughReplayPage from './pages/BreakThroughReplayPage';

// Match pages
import MatchingPage from './pages/MatchingPage';
import CodeIdPage from './pages/CodeIdPage';

// Leaderboards
import Leaderboard from './pages/Leaderboard';

import './App.css';
import { AuthContextProvider } from './context/AuthContext';





function App() {

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <NavBar/>
          <div style={{backgroundColor : 'white'}}>
            <Routes>
              <Route path = '/auth' element={<AuthenticationPage/>}></Route>
              <Route path = "/" element={<RequireAuthContext child={<Landing/>}/>}></Route>
              <Route path = "/tictactoe" element={<RequireAuthContext child={<TicTacToe/>}/>}></Route>
              <Route path = "/tttreplay" element={<RequireAuthContext child={<TTTReplayPage/>}/>}></Route>
              <Route path = "/matchup" element={<RequireAuthContext child={<MatchingPage/>}/>}></Route>
              <Route path = "/get_code_id" element={<RequireAuthContext child={<CodeIdPage/>}/>}></Route>
              <Route path = "/breakthrough" element={<RequireAuthContext child={<BreakThrough/>}/>}></Route>
              <Route path = "/leaderboard" element={<RequireAuthContext child={<Leaderboard/>}/>}></Route>
              <Route path = "/btreplay" element={<RequireAuthContext child={<BreakThroughReplayPage/>}/>}></Route>
            </Routes>
          </div>
      </BrowserRouter>
      <Footer />
    </AuthContextProvider>
    );
}

export default App;
