import { Router } from "react-router-dom";

export function getJwtToken() {
    return sessionStorage.getItem("jwt")
}

export function removeJwtToken() {
    sessionStorage.removeItem('jwt');
}

export function setJwtToken(token : any) {
    const obj = JSON.parse(token);
    sessionStorage.setItem("jwt", obj.access_token)
}

// Longer duration refresh token (30-60 min)
export function getRefreshToken() {
    return sessionStorage.getItem("refreshToken")
}

export interface FormContents {
    password : string,
    email : string
}