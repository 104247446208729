import Card from 'react-bootstrap/Card';
import { useState } from 'react';
import { Button, Form, Dropdown, DropdownButton, Stack } from 'react-bootstrap';
import { useGetCodeId } from '../hooks/useGetCodeId';
import AlertWrapperWithCopy from './AlertWrapperWithCopy';

function CodeIdCard() {

    const { message, alert, code_id, getCodeId } = useGetCodeId();

    const [game, changeGame] = useState("Tic-Tac-Toe");

    const handleSubmit = async () => {
        // const ctx = useAuthContext();

        var fgame : string = "";
        if (game == "Tic-Tac-Toe") {
            fgame = "tic-tac-toe"; 
        } else if (game == "BreakThrough") {
            fgame = "breakthrough";
        } else {
            console.log("invalid game recieved");
        }

        await getCodeId(fgame);
    }

    return (
        <Card>
          <Card.Body>
            <Card.Title>Get my ID</Card.Title>
            <Card.Text>
                Use your agent ID to challenge someone else!
            </Card.Text>
            <Form>
                <Form.Group>    
                    <Form.Label>Game</Form.Label>
                    <DropdownButton
                        variant="outline-secondary"
                        title={game}
                        id="input-group-dropdown-1"
                        >
                        <Dropdown.Item onClick={() => {changeGame("BreakThrough");}}>Breakthrough</Dropdown.Item>
                        <Dropdown.Item onClick={() => {changeGame("Tic-Tac-Toe");}}>Tic Tac Toe</Dropdown.Item>
                    </DropdownButton>
                </Form.Group>
            </Form>
            <Stack style={{marginTop : '3%'}}>
                <Button variant="outline-secondary" type="submit" onClick={handleSubmit}>
                    Get my agent!
                </Button>
                <Stack style={{marginTop : '3%'}}>
                    <AlertWrapperWithCopy alert_level={alert} alert_msg={message} game_id={code_id}></AlertWrapperWithCopy>
                </Stack>
            </Stack>
          </Card.Body>
        </Card>
      );
  }
  
  export default CodeIdCard;