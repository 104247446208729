import React, { useEffect, useState } from "react";
import GameCard from "../components/GameCard";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "../App.css"
import { isClassLike } from "typescript";

function Landing() {

    const default_output : JSX.Element[] = [];

    const [loading, setLoading] = useState(false);
    const [output, setOutput] = useState(default_output);

    type Pair = [string, string, string];
    type Pairs = Pair[];

    useEffect(() => {
        setLoading(true);
        setOutput([]);
        const defaultBT : Pair = ["Break Through (Practice)", "/icons/bt.png", "/breakthrough"];
        const defaultTT : Pair = ["Tic Tac Toe (Practice)", "/icons/ttt.png", "/tictactoe"]
        let p_arr: Pairs = [defaultBT, defaultTT]
        let p_mapped : JSX.Element[] = p_arr.map((v : Pair) => {
            return (
                <Col>
                    <GameCard game_title={v[0]} image_link={v[1]} game_page={v[2]}/>
                </Col>
                )
            });
        setOutput(p_mapped);
        setLoading(false);
        }
    );

    return (
        <div>
            <Container>
                <div style={{fontFamily : 'monospace', fontSize: '50px', padding:'2%'}}>
                    Hello!
                </div>
            </Container>
            <Container style={{backgroundColor:'white', padding:'2%'}}>
                <div style={{fontFamily : 'monospace', fontSize: '25px', padding: '10px'}}>
                    Play A Game
                </div>
                {loading == true ? (<div>Loading...</div>) : (
                <Row xs={1} md={2} lg={4} className="g-4">
                    {output}
                </Row>)} 
            </Container>
        </div>
    );
}

export default Landing