import { Card, Row, Button, Col, Container } from "react-bootstrap";
import TTTReplayBoard from "./TTTReplayBoard";
import React, { forwardRef } from "react";

interface ReplayCardInput {
    set_display  : React.Dispatch<any>;
    replay_id : string;
    timestamp : string;
    score : number;
    replay: any;
}

const TTTReplayCard = ({set_display, replay_id, timestamp, score, replay} : ReplayCardInput) => {

    const moves = replay.map((move: any) => ({
        position: move
    }));

    const updatePage = () => {
        console.log("updating ui to: " + replay_id);
        let selected_id = replay_id;
        let newReplay = React.createElement(TTTReplayBoard, { selected_id, moves }, null);
        set_display(<Container>
            loading...
        </Container>)
        setTimeout(() => runReplay(newReplay), 1);
    }

    const runReplay = (newReplay : React.FunctionComponentElement<any>) => {
        set_display(<Container id={replay_id}>
            {newReplay}
        </Container>
        );
    }

    return (
        <Card style={{padding:'3%'}}>
            <Row>
                <Col>
                    <Card.Title>{score}</Card.Title>
                </Col>
                <Col md="auto" style={{padding:"1%", marginRight:"2%"}} >
                    <Button variant="dark" size="sm" onClick={updatePage}>
                    Replay
                    </Button>
                </Col>
            </Row>
            <Card.Subtitle>{replay_id}, {timestamp}</Card.Subtitle>
        </Card>
    );
}

export default TTTReplayCard;