import React, { useEffect, useState } from 'react';
import { Container, Button, Row } from 'react-bootstrap';

export interface Position {
    position : number[];
}

export interface Move {
    startPosition : Position;
    endPosition : Position;
}

interface BTReplayBoardProps {
    selected_id : string;
    moves : Move[];
}

const BTReplayBoard: React.FC<BTReplayBoardProps> = ({ selected_id, moves }) => {
    
    const defaultboard : string[][] = [ 
                                        ['X', 'X', 'X', 'X', 'X', 'X'],
                                        ['X', 'X', 'X', 'X', 'X', 'X'],
                                        ['', '', '', '', '', ''],
                                        ['', '', '', '', '', ''],
                                        ['O', 'O', 'O', 'O', 'O', 'O'],
                                        ['O', 'O', 'O', 'O', 'O', 'O'],
                                    ]

    const [board, setBoard] = useState<string[][]>(defaultboard);


    const renderMove = (move: Move, moveIndex: number) => {
        setBoard(prevBoard => {
            const newBoard = [...prevBoard];
            const startPos = move.startPosition;
            const endPos = move.endPosition;
            const [srow, scol] = startPos.position;
            const [erow, ecol] = endPos.position;
            newBoard[srow][scol] = ' ';
            newBoard[erow][ecol] = moveIndex % 2 === 0 ? 'X' : 'O';
            return newBoard;
        });
    };

    const play = () => {
        
        const playReplay = () => {
            let moveIndex = 0;
            setBoard(defaultboard);
            
            const nextMove = () => {
                if (moveIndex < moves.length) {
                    renderMove(moves[moveIndex], moveIndex);
                    moveIndex++;
                    setTimeout(nextMove, 1000); // Adjust the timing as needed
                }
            };
            nextMove();
        };

        playReplay();
    }

    return (
        <Container id={selected_id} style={{padding:'2%'}}>
            <Row>
                Replay for {selected_id}
            </Row>
            <div id={selected_id} className="btboard">
            {board.map((row, rowIndex) => (
                <div id={selected_id} key={rowIndex} className="btrow">
                    {row.map((cell, colIndex) => (
                        <div id={selected_id} key={colIndex} className="btcell">
                            {cell}
                        </div>
                    ))}
                </div>
            ))}
        </div>
        <Button variant="dark" size="sm" onClick={play}>Start Replay</Button>
        </Container>
    );
};

export default BTReplayBoard;
