import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";
import { setJwtToken } from "../scripts/JWTToken";
import GLOBAL_PATH from "./GlobalAPI";

const LOGINPATH = `${GLOBAL_PATH}login`;

export const useLogin = () => {
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setLoading] = useState(false);
    const { dispatch } = useAuthContext();
    const navigate = useNavigate();

    const login = async (email: string, password: string) => {
        setLoading(true);
        setError(null);

        const loginData = { email, password };

        try {
            const resp = await fetch(LOGINPATH, {
                method: 'POST',
                credentials: 'include',
                headers: { 
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(loginData),
            });

            const json = await resp.json();
            
            if (!resp.ok) {
                setLoading(false);
                setError(json.detail || "An error occurred during login");
                console.error("Login error:", json);
            } else {
                setJwtToken(JSON.stringify(json));  // This might be unnecessary if the token is in the cookie
                dispatch({ type: 'LOGIN', payload: json });
                setLoading(false);
                navigate('/');
            }
        } catch (error) {
            setLoading(false);
            setError("An error occurred during login. Please try again.");
            console.error("Fetch error:", error);
        }
    };

    return { login, isLoading, error };
};
