import { Card } from "react-bootstrap"
import { RankInput } from "./Scorelist";

const ScoreCard = ({email, elo, rank} : RankInput) => {
    return (
        <Card style={{fontFamily : 'monospace', width : '100%'}}>
            <Card.Body>
                <Card.Title>{rank}. {email}</Card.Title>
                <Card.Subtitle>Elo: {elo}</Card.Subtitle>
            </Card.Body>
        </Card>
    );
}

export default ScoreCard;